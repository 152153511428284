.validando-email {
    background-color: #FFF;
    padding: 2rem;
    overflow: auto;
    position: absolute;
    border-radius: 15px;
    border: 1px solid #CCC;
    display: flex;
    flex-direction: column;
  }
  
  .close-validando {
    color: #ff0a53;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .title-validando {
    color: #2567B7;
    font-size: 18px;
    font-weight: bold;
  }
 
  .buttons {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
  }

  .no-button {
    height: 2.5rem;
    width: 100%;
    padding-left: 1rem;
    background-color: #9e0000;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }

  .no-button:hover {
   opacity: 0.7;
  }

  .yes-button {
    height: 2.5rem;
    width: 100%;
    padding-left: 1rem;
    background-color: #009e2a;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }

  .yes-button:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 768px) {
    
  }