@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

.App {
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  background-image: url(./assets/images/trabalho.jpg);
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.negrito {
  font-weight: bold;
}

.container {
  background-color: rgba(35, 82, 116, 0.88);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.side-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.logo-vhsys {
  width: 323px;
  height: 120px;
}

.logo-vhsys img {
  width: 100%;
  height: 100%;
}

.side-left h2 {
  color: white;
  font-size: 42px;
  font-weight: 400;
  padding-bottom: 1rem;
  margin: 0;
}

.side-left p {
  color: white;
  font-size: 18px;
}

.side-left ul {
  color: white;
  font-size: 18px;
}

.youtube-iframe {
  width: 100%;
  aspect-ratio: 16/9;
  -webkit-aspect-ratio: 16/9;
}

.side-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.box {
  background-color: #eefde6;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
}

.box-top {
  flex: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0rem 5vh;
}

.box-top h1 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #0e2a3e;
  font-size: 20px;
}

.box-center form {
  display: flex;
  flex-direction: column;
}

.box-center form label {
  margin: 0;
  margin-bottom: 3px;
  padding: 0;
  font-size: 12px;
  color: #0e2a3e;
}

.box-center form input {
  margin-bottom: 1vh;
  height: 5vh;
  width: 100%;
  padding-left: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: black;
}

.box-center form textarea {
  margin-bottom: 1vh;
  height: 5vh;
  width: 100%;
  padding-left: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: black;
}

.box-bottom {
  flex: 1;
}

.box-footer {
  margin: 0rem 0rem;
}

.checkbox-div {
  color: #0e2a3e;
}

.checkbox-div a {
  color: #0e2a3e;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.box-bottom button {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  height: 5vh;
  width: 100%;
  background-color: #50ad22;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.box-bottom button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 960px) {
  .container {
    background-color: rgba(35, 82, 116, 0.88);
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-position: center;
    gap: 50px;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .side-left {
    display: flex;
  }

  .box {
    width: 90%;
  }

  .box-top h1 {
    font-size: 21px;
  }

  .box-center form input {
    margin-bottom: 0.3rem;
    height: 2.5rem;
    width: 100%;
    padding-left: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: black;
  }

  .box-center form textarea {
    margin-bottom: 0.3rem;
    height: 2.5rem;
    width: 100%;
    padding-left: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: black;
  }

  .box-bottom button {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    height: 2.5rem;
    width: 100%;
    background-color: #50ad22;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
}
